import {connect} from "react-redux";
import Property from '../../containers/pages/property'
import {property_set_sidebar, property_set_view} from "../../actions/property";
import {
  convertObjectToString, createClient, createClientPchat, getBrowser, getErrorMessage,
  getTitleSPByType,
  loginWithDraws
} from "../../constants/utils";
import {basic_set_draw_info, basic_set_loading, basic_set_open_snackbar} from "../../actions/basic";
import {GET_DRAW_BY_ID, GET_ME_DRAW, GET_PROPERTY_VIEW, GET_USER_PROPERTY_BY_PROPERTY_ID} from "../../share/query";
import {TEXT_LIST} from "../../constants/index";
import {REGISTER_USER_PROPERTY, UPDATE_AGREED_USER_PROPERTY} from "../../share/mutation";

let property_sidebar = {};
let property_view = {};
let me = {};
let user_property = null;
let get_user_property = null;

const mapStateToProps = (state) => {
  property_sidebar = state.property.property_sidebar;
  property_view = state.property.view;
  me = state.basic.me || {};
  
  return {
    property_sidebar: state.property.property_sidebar,
    property_view: state.property.view,
    me: state.basic.me,
    draw: state.basic.draw_info
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async (id) => {
      await  getPropertyViewData(id,dispatch)
    },
    // onLoadIframe: async() => {
    //   if (property_sidebar.type !== 'user') {
    //     await onLoadIframe(dispatch, property_view.id);
    //   }
    // },
    onChangeSidebar: (property_sidebar, type) => {
      if (property_sidebar.type === type) {
        property_sidebar.status = !property_sidebar.status;
      } else {
        property_sidebar.status = true;
      }
      
      property_sidebar.type = type;
      onChangeClose(dispatch, !property_sidebar.status);
      
      dispatch(property_set_sidebar(property_sidebar));
    },
    onChangePropertyList: (history) => {
      dispatch(property_set_sidebar({}));
      dispatch(property_set_view({}));
      return history.push('/');
    },
    onChangeClose: (property_sidebar, status) => {
      onChangeClose(dispatch, status);
    },
    onChangeColor: (material_id, category_group_detail) => {
      onChangeColor(dispatch, material_id, category_group_detail);
    },
    onChangeCombo: (combo) => {
      onChangeCombo(dispatch, combo);
    },
    onChangeExtension: (extension_id, node_type_name) => {
      onChangeExtension(dispatch, extension_id, node_type_name);
    },
    onChangeDialog: (id) => {
      let {present_boards} = property_view;
      const index = present_boards.findIndex(present_board => present_board.id === id);
      
      if(index < 0) return;
  
      present_boards = present_boards.map((present_board) => ({...present_board, active: false}));
      present_boards[index].active = true;
  
      property_view.present_boards = present_boards;
      dispatch(property_set_view(property_view));
      
      const type = getTitleSPByType('popup');
      const data = {type: type, value: {popup_url: present_boards[index].attachment.file_url}};
      postMessage(data);
    },
    onChangeDistance: (active) => {
      let value = 'false';
      if (active === 'title_active') {
        value = 'true';
      } else if (active === 'title_no_active') {
        value = 'false';
      }
      property_sidebar.distance = active;
      dispatch(property_set_sidebar(property_sidebar));
      
      const distance = getTitleSPByType('distance');
      const data = {type: distance, value: value};
      postMessage(data);
    },
    onChangeSave: async (material, category_group_detail_active) => {
  
      if (!category_group_detail_active) {
        const name = material.new_name || material.name;
        return dispatch(basic_set_open_snackbar({type: 'error', message: name + ' not found'}))
      }
    
      if (!me || !me.id) {
        return dispatch(basic_set_open_snackbar({type: 'error', message: TEXT_LIST.please_login}))
      }
      
      const client = createClient();
      const property_id = property_view.id;
      const {category_group_details} = material.category_group;
      const cgd_index = category_group_details.findIndex(cgd => cgd.id === category_group_detail_active);
      const cgd = category_group_details[cgd_index];
      //Map upMaterialInput
      const upMaterialInput = {
        material_active: material.id,
        category_group_detail_active: cgd.id,
        category_group_detail_price_active: cgd.category_group_detail_price.id,
      };
      let variables = {mode: 'ADD', type: 'material', property_id, upMaterialInput};
      
      if (user_property && user_property.id) {
        variables.mode = 'EDIT';
        variables.id = user_property.id;
      }
    
      await client.request(REGISTER_USER_PROPERTY, {userPropertyInput: variables}).then(async data => {
        dispatch(basic_set_open_snackbar({message: TEXT_LIST.has_updated}));
  
        await getUserProperty(dispatch, property_id);
        
        //Set saved materials
        const material_index =  property_view.bundle.materials.findIndex(m => m.id === material.id);
        property_view.bundle.materials[material_index].saved = true;
        dispatch(property_set_view(property_view));
      
      }).catch(e => {
        dispatch(basic_set_open_snackbar({type: 'error', message: getErrorMessage(e)}))
      });
    },
    onChangeAgreed: (material_id) => {
      if (!window.confirm(TEXT_LIST.can_i_save)) return true;
    
      const client = createClient();
      client.request(UPDATE_AGREED_USER_PROPERTY, {id: user_property.id, material_id}).then(async data => {
        if (data.updateAgreedUserProperty) {
          dispatch(basic_set_open_snackbar({message: TEXT_LIST.has_updated}));
        
          const material_index = property_view.bundle.materials.findIndex(material => material.id === material_id);
          property_view.bundle.materials[material_index].agreed = true;
          dispatch(property_set_view(property_view));
        }
      
      }).catch(e => {
        dispatch(basic_set_open_snackbar({type: 'error', message: getErrorMessage(e)}))
      });
    },
    onChangeExport: () => {
      if (!me || !me.id) {
        return dispatch(basic_set_open_snackbar({type: 'error', message: TEXT_LIST.please_login}))
      }
      
      const bundle_data = convertObjectToString(convertBundleData());
      return download(bundle_data);
    },
    focusIframe: () => {
      document.getElementById('3d-render').contentWindow.focus();
    },
    loginWithDraw: async (draw_id,u_id,id) =>{
      const clientPchat = createClientPchat();
     try {
        const drawResult = await clientPchat.request(GET_DRAW_BY_ID,{drawId:draw_id});
        if(drawResult){
          dispatch(basic_set_draw_info(drawResult.getDrawById))
        }
        const meDrawData = await clientPchat.request(GET_ME_DRAW);
        await loginWithDraws(meDrawData.meDraw.email,u_id,meDrawData.meDraw.id,dispatch)
        dispatch(basic_set_loading(true));
      
        await  getPropertyViewData(id,dispatch)
     } catch (e) {
      localStorage.removeItem("sp-client-token");
      let error = getErrorMessage(e, dispatch);
      if(error === "User does not exist") error = "pchatにログインしてください"
      dispatch(basic_set_open_snackbar({type: 'error', message: error}));
     }
    }
  }
};

const onChangeColor = (dispatch, material_id, category_group_detail) => {
  const material_index = property_view.bundle.materials.findIndex(material => material.id === material_id);
  const { category } = category_group_detail;
  const { attachment } = category;
  
  property_view.bundle.materials[material_index].category_group.category_group_detail_active = category_group_detail.id;
  dispatch(property_set_view(property_view));
  
  const type = getTitleSPByType('texture');
  const data = {
    type: type,
    id: category.id,
    src: attachment.file_url,
    material_name: property_view.bundle.materials[material_index].name || ''
  };
  postMessage(data);
};

const onChangeCombo = (dispatch, combo) => {
  property_view.bundle.combo_active = combo.id;
  dispatch(property_set_view(property_view));
  const {combo_materials} = combo;
  
  const type = getTitleSPByType('combo');
  const set_combo = combo_materials.map((combo_material) => ({
    id: combo_material.category.id,
    src: combo_material.category.attachment.file_url,
    material_name: combo_material.material.name || ''
  }));
  
  const data = {type, combo: set_combo};
  postMessage(data);
};

const onChangeExtension = (dispatch, extension_id, node_type_name) => {
  const extension_index = property_view.bundle.extensions.findIndex(extension => extension.id === extension_id);
  property_view.bundle.extensions[extension_index].node_type_active = node_type_name;
  dispatch(property_set_view(property_view));
  
  const {node_types} = property_view.bundle.extensions[extension_index];
  const node_types_hide = node_types.filter(node_type => node_type.name !== node_type_name).map(node_type => node_type.name);
  
  const type = getTitleSPByType('node_type');
  const data = {type, show: [node_type_name], hide: node_types_hide};
  postMessage(data);
};

// const resetMaterials = () => {
//   const reset_material = getTitleSPByType('reset_material');
//   postMessage({type: reset_material});
// };

const getPropertyViewData =async (id,dispatch)=>{
  dispatch(basic_set_loading(true));
      
  const client = createClient();
  await client.request(GET_PROPERTY_VIEW, {id: id}).then(async data => {
    property_view = data.getPropertyView;
    dispatch(property_set_view(property_view));
    
    property_sidebar.distance = 'title_no_active';
    property_sidebar.type = 'property';
    property_sidebar.status = true;
    dispatch(property_set_sidebar(property_sidebar));
    
    onChangeClose(dispatch, false);
    

    await getUserProperty(dispatch, id);
    //Load iframe;
    await onLoadIframe(dispatch, property_view.id);
    
  }).catch(e => {
    dispatch(basic_set_open_snackbar({type: 'error', message: getErrorMessage(e)}))
  });
}

const onChangeClose = (dispatch, status) => {
  const sidebar = document.getElementById('sidebar');
  const render = document.getElementById('render');
  if (!sidebar || !render) return;
  
  if (status) {
    sidebar.style.display = 'none';
    render.style.width = '100%';
    
    property_sidebar.status = false;
    dispatch(property_set_sidebar(property_sidebar));
  }
  else {
    sidebar.style.display = 'flex';
    render.style.width = 'calc(100% - 280px)';
  }
};

const postMessage = (data) => {
  document.getElementById('3d-render').contentWindow.postMessage(data, '*');
};

const onLoadIframe = async (dispatch) => {
  const iframe_url = process.env.REACT_APP_IFRAME;
  const new_url = new URL(iframe_url);
  const check_browser = getBrowser();
  
  let iframe = document.createElement('iframe');
  iframe.key = new Date();
  iframe.id = "3d-render";
  iframe.className = "3d-render";
  iframe.src = iframe_url;
  iframe.title = "prefab 3D";
  
  if (check_browser === 'Apple Safari') {
    iframe.allow = 'fullscreen ' + new_url.origin;
  } else {
    iframe.allow = "xr-spatial-tracking";
    iframe.fullScreen = true;
  }
  
  document.getElementById('render').appendChild(iframe);
  
  window.addEventListener('message', function (e) {
    // console.log('e.data==>', e.data)
    if (e.data && e.data.iframe_loaded && !e.data.on_scene_load_complete) {
      iframe.addEventListener("load", eventLoad());
    }
    
    // Receive data from iframe.
    if (e.data && e.data.on_scene_load_complete) {
      propertyMapBundleData(dispatch, get_user_property);
    }
  });
  
  function eventLoad(status) {
    const {sp_root_url, sp_detail_url} = property_view.bundle;
    const url = sp_root_url;
    const url_detail = sp_detail_url;
    const time = status === 'loaded' ? 800 : 1500;
    
    // const url = 'https://3d-cg.jp/KT/sample/kodate/color-change/';
    // const url_detail = 'https://3d-cg.jp/KT/sample/kodate/color-change/2022-07-26-18-12-04/';
    const load_url = getTitleSPByType('load_url');
    const data = {type: load_url, url: url, url_detail: url_detail};
    postMessage(data);
    
    setTimeout(() => {
      dispatch(basic_set_loading(false));
    }, time);
  }
};

const getUserProperty = async (dispatch, id) => {
//Get user_property selected
  const client = createClient();
  await client.request(GET_USER_PROPERTY_BY_PROPERTY_ID, {property_id: id}).then(data => {
    const {getUserPropertyByPropertyId} = data;
    const bundle = getUserPropertyByPropertyId ? getUserPropertyByPropertyId.bundle_data : null;
    
    user_property = getUserPropertyByPropertyId;
    get_user_property = {id: id, bundle: bundle};
  });
};

const propertyMapBundleData = (dispatch, get_user_property) => {
  if (get_user_property && get_user_property.bundle) {
    
    const get_combo_active = get_user_property.bundle.combo_active || '';
    const get_materials = get_user_property.bundle.materials || [];
    const get_extensions = get_user_property.bundle.extensions || [];
    const {combos, materials, extensions} = property_view.bundle;
    
    //On Change combo
    if (get_combo_active) {
      const combo_index = combos.findIndex(cb => cb.id === get_combo_active);
      if (combo_index > -1) {
        property_view.bundle.combo_active = get_combo_active;
        onChangeCombo(dispatch, combos[combo_index]);
      }
    }
    
    //On Change material;
    if (get_materials && get_materials.length) {
      for (const get_material of get_materials) {
        
        const {category_group, material_active, saved, agreed} = get_material
        
        const cgd_active = category_group.category_group_detail_active || '';
        const material_index = materials.findIndex(material => material.id === material_active);
        
        if (material_index > -1 && cgd_active) {
          property_view.bundle.materials[material_index].category_group.category_group_detail_active = cgd_active;
          property_view.bundle.materials[material_index].saved = !!saved; //Set saved materials
          property_view.bundle.materials[material_index].agreed = !!agreed; //Set agreed materials
          
          const {category_group_details} = materials[material_index].category_group;
          const cgd_index = category_group_details.findIndex(cgd => cgd.id === cgd_active);
          
          if (cgd_index > -1) {
            onChangeColor(dispatch, material_active, category_group_details[cgd_index]);
          }
        }
      }
    }
    
    //On change extension
    if (get_extensions && get_extensions.length) {
      for (const get_extension of get_extensions) {
        
        const get_node_type_active = get_extension.node_type_active || '';
        const extension_index = extensions.findIndex(extension => extension.id === get_extension.extension_active);
        
        if (extension_index > -1 && get_node_type_active) {
          property_view.bundle.extensions[extension_index].node_type_active = get_node_type_active;
          
          onChangeExtension(dispatch, get_extension.extension_active, get_node_type_active);
        }
      }
    }
    
    dispatch(property_set_view(property_view));
  }
  
  property_sidebar.action = true;
  property_sidebar.on_scene_load_complete = true;
  dispatch(property_set_sidebar(property_sidebar));
};

const convertBundleData = () => {
  const {materials, combo_active, extensions} = property_view.bundle;
  let bundle = {};
  bundle.id = property_view.bundle.id;
  bundle.user_id = me.id || '';
  bundle.property_id = property_view.id;
  bundle.combo_active = combo_active || '';
  bundle.materials = [];
  bundle.extensions = [];
  
  for (let material of materials) {
    if (material.category_group && material.category_group.category_group_detail_active) {
      const { category_group_details, category_group_detail_active } = material.category_group;
      const cgd_index = category_group_details.findIndex(cgd => cgd.id === category_group_detail_active);
      const cgd_price = category_group_details[cgd_index].category_group_detail_price;
      
      bundle.materials.push({
        material_active: material.id,
        category_group: {
          category_group_active: material.category_group.id,
          category_group_detail_active: category_group_detail_active,
          category_group_detail_price_active: cgd_price.id,
        }
      });
    }
  }
  
  for (let extension of extensions) {
    if (extension.node_type_active) {
      bundle.extensions.push({
        extension_active: extension.id,
        node_type_active: extension.node_type_active
      })
    }
  }
  
  return bundle;
};

const download = (text) => {
  const filename = 'shapepark-export.txt';
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  
  element.style.display = 'none';
  document.body.appendChild(element);
  
  element.click();
  
  document.body.removeChild(element);
};

const CProperty = connect(mapStateToProps, mapDispatchToProps)(Property);

export default CProperty;